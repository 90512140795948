import React, { useContext, useState, useEffect, useRef } from 'react'
import BuyerContext from '../../Product/context/buyer/buyerContext'
import AuthContext from '../../Product/context/auth/authContext'
import PluginContext from '../../Product/context/plugin/pluginContext'
import ProductContext from '../../Product/context/product/productContext'
import NoRecordsFound from '../../Product/components/atoms/NoRecordsFound'
import SlidePanel from '../SlidePanel'
import { connect } from 'react-redux'
import {
    currencyFormat,
    dateFormatFrontDay,
    handleRedirectInternal,
} from '../../Product/common/components'
import { Pagination } from '@material-ui/lab'
import { Link, useHistory } from 'react-router-dom'
import Timer from '../../Product/common/timer'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { imageError, getImageurl } from '../../utils/commonFunctions'
import MultisellerSlider from '../SlidePanel/MultisellerSlider'
import Loader from '../../assets/loader'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import axios from 'axios'
import AlertContext from '../../Product/context/alert/alertContext'
import RetractBid from './RetractBid'
import CurrencyConverterFormat from '../../utils/CommonFunctionality/CurrencyConverter/CurrencyConverterFormat'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Popup from '../../Product/components/organisms/Popup'
import UserContext from '../../Product/context/user/userContext'
import Dispute from './Dispute'
import RequestRefund from './RequestRefund'
import Requestplugin from './Requestplugin'
import AddFeedback from '../../utils/CommonFunctionality/Feedback/AddFeedback'
import AdditionalPayment from './AdditionalPayment'
import CustomDialog from '../../Product/components/organisms/Dialog'
import InvoiceMessage from '../../utils/CommonFunctionality/Message/InvoiceMessage'
import { Chip } from '@material-ui/core'
import { socket } from '../../Product/common/socket'
import CheckBox from '../../Product/components/atoms/CheckBox'
import MultiPickup from '../../utils/CommonFunctionality/Scheduler/MultiPickup'
import CustomSelect from '../../Product/components/atoms/Inputs/CustomSelect'
import LotListProduct from '../ProductCard/lotListProduct'

const AllBids = (props) => {
    const buyerContext = useContext(BuyerContext)
    const productContext = useContext(ProductContext)
    const pluginContext = useContext(PluginContext)
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)
    const [auction_list, setAuctionlist] = useState([])
    const {
        getAllShippingAddress,
        all_shipping_address,
        static_page,
        getStaticPage,
        responseStatus: responseStatusUser,
    } = userContext
    const { getAbandonedOrders, responseStatus: pluginResponseStatus } = pluginContext

    const history = useHistory()
    const { t } = useTranslation()
    const {
        buyer_allproducts,
        getAllBuyerProducts,
        updateVideoCount,
        product_view,
        getViewProduct,
        check_get_shipping_rate_api,
        shipping_rate_calculation,
        clear_ship_station_amount,
        getAllMakeRequest,
        list_make_request,
        markasrecived,
        getAllAuctionProducts,
        all_auctions,
        responseStatus: buyerresponsestatus,
        clearResponse,
    } = buyerContext
    const { isAuthenticated, user, updateMessageCount, userCount } = authContext
    const buyerAllProductsRef = useRef(buyer_allproducts)
    const userCountRef = useRef(userCount)
    const userRef = useRef(user)
    useEffect(() => {
        buyerAllProductsRef.current = buyer_allproducts
        userCountRef.current = userCount
        userRef.current = user
    })
    const [btnloading, setBtnloading] = useState(false)

    const [loading, setLoading] = useState(true)
    const [Items, setData] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [abandonedOrders, setAbandonedOrders] = useState({
        products: [],
        page: 1,
        limit: 10,
        totaProducts: 0,
    })
    const [totalItems, setTotalItem] = useState(0)
    const [toInvoice, settoInvoice] = useState(false)
    const [toInvoiceID, settoInvoiceID] = useState(false)
    const [popup_open, setPopupopup] = useState(false)
    const [refundTrigger, setRefundTrigger] = useState(false)
    const [feedbackOpen, setFeedbackOpen] = useState(false)
    const [feedbackItem, setFeedbackItem] = useState({})
    const [additionalPayOpen, setAdditionalPayOpen] = useState(false)
    const [additionalPayItem, setAdditionalPayItem] = useState({})
    const [popupchecked, setPopupchecked] = useState(false)
    const [termscondtions, setTermscondition] = useState('')
    const [selectAddress, setSelectAddress] = useState([])
    const [display_address, setDisplayAddress] = useState({})
    const [messageChecked, setMessageChecked] = useState(false)
    const [date_closed, setDateClosed] = useState(new Date())
    const [paid_date, setPaidDate] = useState(new Date())
    const [lotof, setLotof] = useState('')
    const [buynowId, setBuynowId] = useState({})
    const { responseStatus, similar_products_qty, getSimilarProductsQty, clearSimilarQty } =
        productContext
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const [search, setSearch] = useState({
        status: props.page,
        sh_limit: 100,
        page: 1,
        order: props.page == 'bidlost' ? 2 : 1,
        ftpImage: true,
        buynow_only_io: props.page == 'buynow' ? 1 : undefined,
        auction_only_io:
            props.page == 'bidwon' && global.pluginConfiguration?.make_an_offer?.enable
                ? 1
                : undefined,
        search: '',
        buynow_with_qty:
            props.page == 'buynow' && global?.storeConfigration?.buy_now_with_qty?.value
                ? 1
                : undefined,
        get_msg_count: global?.storeConfigration?.unique_slot4?.value == 1 ? 1 : 0,
        sch_type: props.sch_type,
        auction_lot_filter: global.storeConfigration?.auction_dashboard_with_filter?.value ? 1 : 0,
        paid:
            props.sch_type == 1 || props.paid == 1 ? 'paid' : props.unpaid == 1 ? 'unpaid' : 'all',
        lotof: '',
    })

    const [searchValue, setSearchValue] = useState('')
    const [invoiceId, setInvoiceId] = useState([])
    const [scheduleInvoiceId, setScheduleInvoiceId] = useState([])
    const [trigger, setTrigger] = useState(false)
    const [similarQty, setSimilarQty] = useState([])
    const [initial, setInitial] = useState(true)
    const [checkoutLength, setCheckoutLength] = useState(0)
    const [scheduleLength, setScheduleLength] = useState(0)

    const onHandlePage = (event, value) => {
        if (props.abandoned) {
            getAbandonedOrders({
                user_id: user?.id,
                limit: 10,
                page: value,
            })
            setAbandonedOrders({
                ...abandonedOrders,
                page: value,
            })
        } else {
            setSearch({ ...search, page: value })
            if (props.page == 'make_an_offer') {
                getAllMakeRequest({
                    user_id: user?.id,
                    status: 'Accepted',
                    limit: 10,
                    page: value,
                })
            }
        }
    }
    const categoryTypes = {}
    global.allCategories.map((catgeory) => (categoryTypes[catgeory.id] = catgeory))
    useEffect(() => {
        if (props.page != 'make_an_offer') {
            setLoading(false)
            setData(buyer_allproducts.results)
            setTotalItem(buyer_allproducts.total_pagecnt)
            setScheduleInvoiceId([])
            setScheduleLength(0)
        }
    }, [buyer_allproducts])
    useEffect(() => {
        if (responseStatus && responseStatus !== '') {
            if (responseStatus === 'buynow_updated_successfull') {
                getAllBuyerProducts(search)
            } else {
            }
        } else {
        }
    }, [responseStatus])
    // useEffect(() => {
    //     // if (user) {
    //     socket.on('insertMessages_count', (data) => {
    //         if (buyerAllProductsRef.current?.results?.length) {
    //             buyerAllProductsRef.current?.results.map((val) => {
    //                 if (
    //                     userRef.current?.id == data.to_id &&
    //                     data.project_id == val.common_invoice
    //                 ) {
    //                     console.log(val, 'val')
    //                     val.msg_count += 1
    //                 }
    //             })
    //             updateVideoCount(buyerAllProductsRef.current)
    //         }
    //     })
    //     // }

    //     return () => {
    //         console.log('000000000000')
    //         socket.off('insertMessages_count', (data) => {
    //             return data
    //         })
    //     }
    // }, [])

    useEffect(() => {
        if (buyerresponsestatus) {
            if (buyerresponsestatus.from == 'marke_as_recived') {
                setBtnloading(false)
                if (buyerresponsestatus.status == 'error') {
                    setAlert(buyerresponsestatus?.message?.message, buyerresponsestatus.status)
                } else {
                    if (buyerresponsestatus?.result?.message) {
                        setAlert(buyerresponsestatus.result.message, buyerresponsestatus.status)
                        getAllBuyerProducts(search)
                    }
                }
                clearResponse()
            }
        }
    }, [buyerresponsestatus])

    useEffect(() => {
        if (props.page != 'make_an_offer') {
            setLoading(true)
            if (isAuthenticated) {
                if (props.cancelRequest) {
                    props.cancelRequest.cancel()
                }

                if (props.setCancelRequest) {
                    props.setCancelRequest(props?.cancelToken)
                }
                if (global.storeConfigration?.auction_dashboard_with_filter?.value) {
                    getAllAuctionProducts({
                        site_id: global?.storeDetails?.site_id ? global.storeDetails.site_id : '',
                        type: search.status == 'bidactive' ? 1 : search.status == 'bidwon' ? 2 : 3,
                        user_id: user?.id,
                        sch_type: props.sch_type,
                    })
                }
                var sen_buyer_data = search
                if (global.storeConfigration?.auction_dashboard_with_filter?.value) {
                    sen_buyer_data.lotof = localStorage.getItem('lotof')
                        ? localStorage.getItem('lotof')
                        : undefined
                    setLotof(localStorage.getItem('lotof') ? localStorage.getItem('lotof') : '')
                    localStorage.removeItem('lotof')
                }
                getAllBuyerProducts(sen_buyer_data, props?.cancelToken)
            }
        }
    }, [search, isAuthenticated, refundTrigger, trigger])

    useEffect(() => {
        if (all_auctions.length) {
            var reverse_auction = all_auctions.reverse()
            setAuctionlist([...reverse_auction])
        } else {
            setAuctionlist([])
        }
    }, [all_auctions])

    useEffect(() => {
        if (props.page == 'make_an_offer' && user) {
            setLoading(true)
            getAllMakeRequest({
                user_id: user?.id,
                limit: 10,
                page: 1,
            })
        }
    }, [user])

    useEffect(() => {
        if (props.page == 'bidwon' && props.abandoned && user) {
            console.log('came here.....')
            setLoading(true)
            getAbandonedOrders({
                user_id: user?.id,
                page: 1,
                limit: 10,
            })
        }
    }, [user])

    useEffect(() => {
        if (props.abandoned && pluginResponseStatus?.from == 'getAbandonedOrder') {
            if (pluginResponseStatus.status == 'error') {
                setAlert(pluginResponseStatus?.message?.message, pluginResponseStatus.status)
            } else {
                setAbandonedOrders({
                    ...abandonedOrders,
                    products:
                        pluginResponseStatus?.results !== null &&
                        pluginResponseStatus?.results.length > 0
                            ? pluginResponseStatus.results
                            : [],
                    totaProducts:
                        pluginResponseStatus?.total_count !== null &&
                        pluginResponseStatus?.total_count.length > 0
                            ? pluginResponseStatus.total_count[0].count
                            : 0,
                })
            }
        }
    }, [pluginResponseStatus])

    useEffect(() => {
        setLoading(false)
        if (props.page == 'make_an_offer') {
            if (list_make_request?.data?.length > 0) {
                setData(list_make_request.data)
                setTotalItem(list_make_request.totalcount)
            } else {
                setData([])
                setTotalItem(0)
            }
        }
    }, [list_make_request])

    const handleClose = () => {
        getAllBuyerProducts(search)
        setSelectedProduct(null)
    }

    const handleClick = async (id) => {
        if (props.storeTheme == 'Liquidation_three' || props.storeTheme == 'Lotsofauctions') {
            handleRedirectInternal(history, `product/${id}`)
        } else {
            setSelectedProduct(id)
        }
    }

    const handleInvoiceChange = (id) => {
        var arr = invoiceId
        var arr2 = Items
        var temp = []
        var temp2 = []
        if (arr.includes(id)) {
            temp = arr.filter((val) => val != id)
        } else {
            temp = [...arr, id]
        }
        temp.map((inv) => {
            arr2.filter((val) => val?.common_invoice == inv).map((value) => temp2.push(value))
        })
        setInvoiceId(temp)
        setCheckoutLength(temp2.length)
    }
    const handleScheduleInvoiceChange = (id, location) => {
        var arr = scheduleInvoiceId
        var arr2 = Items
        var temp = []
        var temp2 = []
        var stop = null
        var date_close = new Date()
        var date_paid = new Date()
        if (arr.includes(id)) {
            temp = arr.filter((val) => val != id)
        } else {
            temp = [...arr, id]
        }
        temp.map((inv) => {
            arr2.filter((val) => val?.common_invoice == inv).map((value) => temp2.push(value))
        })
        temp2.map((val, i) => {
            console.log(val.date_closed, 'val.date_closed')
            if (i === 0) {
                date_close = val.date_closed
                date_paid = val.buynowpaid_date
            }
        })
        if (scheduleInvoiceId.length) {
            temp2.map((val, i) => {
                if (val.location_id != location) {
                    stop = true
                }
            })
        }
        if (stop) {
            return setAlert('Kindly add same location invoice!', 'error')
        }
        setDateClosed(date_close)
        setPaidDate(date_paid)
        setScheduleInvoiceId(temp)
        setScheduleLength(temp2.length)
    }

    const handleScheduleCheckinChange = (id, location) => {
        var arr = scheduleInvoiceId
        var arr2 = Items
        var temp = []
        var temp2 = []
        var stop = null
        if (arr.includes(id)) {
            temp = arr.filter((val) => val != id)
        } else {
            temp = [...arr, id]
        }
        temp.map((inv) => {
            arr2.filter((val) => val?.scheduled == inv).map((value) => temp2.push(value))
        })
        if (scheduleInvoiceId.length) {
            temp2.map((val) => {
                if (val.schedule_location_id != location) {
                    stop = true
                }
            })
        }
        if (stop) {
            return setAlert('Kindly add same location invoice!', 'error')
        }
        setScheduleInvoiceId(temp)
        setScheduleLength(temp2.length)
    }

    const handleSeeVideo = (item) => {
        setMessageChecked(true)
        setBuynowId(item)
        if (parseInt(item.msg_count) > 0) {
            updateMessageCount(
                parseInt(userCountRef?.current?.messageCount),
                parseInt(userCountRef?.current?.messageCountProjects) - parseInt(item.msg_count),
            )
            item.msg_count = 0
        }
    }
    const handleInvoiceChangeupdate = (id) => {
        var arr = invoiceId
        var arr2 = Items
        var temp = []
        var temp2 = []
        if (arr.includes(id)) {
            temp = arr.filter((val) => val != id)
        } else {
            temp = [...arr, id]
        }
        temp.map((inv) => {
            arr2.filter((val) => val?.common_invoice == inv && val?.status == 'Accepted').map(
                (value) => temp2.push(value),
            )
        })
        setInvoiceId(temp)
        setCheckoutLength(temp2.length)
    }

    useEffect(() => {
        var arr = invoiceId
        var temp = []
    }, [invoiceId])

    useEffect(() => {
        if (Items.length && initial) {
            let temp_arr = []
            Items.map((val) => {
                if (val.sallertype) {
                    temp_arr.push(val.sallertype)
                }
            })
            if (temp_arr.length) getSimilarProductsQty({ id: temp_arr })
            setInitial(true)
        }
    }, [Items])
    useEffect(() => {
        if (similarQty?.length && initial) {
            let temp_pro = Items
            temp_pro.map((val) => {
                similarQty?.map((qty) => {
                    if (val.sallertype == qty.id) {
                        val.contact_seller = (
                            <Link to="/contact_us" target="_blank" rel="noopener noreferrer">
                                {qty.available_qty} Qty available. If you are still intrested,
                                Kindly contact seller.
                            </Link>
                        )
                    }
                })
            })
            setData([...temp_pro])
            console.log(temp_pro, 'temp_pro')
            setInitial(false)
        }
    }, [similarQty, Items])
    useEffect(() => {
        if (Items?.length) setSimilarQty(similar_products_qty)
    }, [similar_products_qty, Items])

    const retractBid = async (bid) => {
        // console.log('retractBid', bid)
        if (bid.mybidid && user?.id) {
            const formdata = new FormData()

            formdata.append('pid', bid.id)
            formdata.append('id', bid.mybidid)
            formdata.append('uid', user?.id)
            formdata.append('price', bid.mybid)
            formdata.append('auctionid', bid.auctionid)
            formdata.append('lotof', bid.lotof)
            formdata.append('is_buyer', true)
            // console.log('retractBid', formdata)
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api_bidding/cancelbid_process`,
                formdata,
            )
            if (res.data.msg === 'yes') {
                getAllBuyerProducts(search)
                setAlert('Bid Retracted Successfully', 'success')
            } else {
                setAlert(res.data.msg, 'error')
            }
        }
    }

    useEffect(() => {
        if (global.storeConfigration?.ship_track_info?.value == 1 && props.page == 'bidwon') {
            getAllShippingAddress()
        }
    }, [])
    useEffect(() => {
        if (all_shipping_address.length) {
            setSelectAddress(all_shipping_address)
        }
    }, [all_shipping_address])
    useEffect(() => {
        if (global?.storeConfigration?.checkout_terms_and_condtions?.value == 1) {
            getStaticPage(
                {
                    page_id: 'checkout_terms_and_condtions',
                },
                1,
            )
        }
    }, [])
    useEffect(() => {
        if (global?.storeConfigration?.checkout_terms_and_condtions?.value == 1) {
            if (static_page.content) {
                setTermscondition(static_page.content)
            }
        }
    }, [static_page])

    const openshippingtracking = (data) => {
        selectAddress.map((val) => {
            if (parseInt(val.id) === parseInt(data.shipping_addr_id)) {
                setDisplayAddress({ ...val, ...data })
            }
        })
        setPopupopup(true)
    }

    const handleSearch = (event) => {
        event.preventDefault()
        setSearch({
            ...search,
            search: searchValue,
            page: 1,
        })
    }
    // useEffect(() => {
    //     console.log('99999999999999999999999999999999999999999999999999999999999', display_address)
    //     if (Object.keys(display_address).length > 0) {
    //         setPopupopup(true)
    //     }
    // }, [display_address])

    const redirect_checkout = () => {
        if (global.storeConfigration?.auction_dashboard_with_filter?.value) {
            localStorage.setItem('lotof', lotof)
        }
        if (global.pluginConfiguration?.shipping?.enable) {
            var check_hasshipping = 1,
                seller_zip = '',
                get_invoice_id = [],
                seller_zipcode = '',
                no_call_api = 1

            Items.map((data, index) => {
                if (invoiceId.includes(data.common_invoice) && parseInt(data.hasshipping)) {
                    if (data.custom_field_8) {
                        if (
                            seller_zipcode != JSON.parse(data.custom_field_8).zipcode &&
                            seller_zipcode != ''
                        ) {
                            no_call_api = 0
                        } else {
                            seller_zipcode = JSON.parse(data.custom_field_8).zipcode
                        }
                    }
                    check_hasshipping = 0
                    if (data.sell_zipcode != seller_zip && seller_zip != '') {
                        no_call_api = 0
                    } else {
                        seller_zip = data.sell_zipcode
                    }
                    if (
                        (data.weight != 0 &&
                            data.height != 0 &&
                            data.length != 0 &&
                            data.width != 0) ||
                        (data.custom_field_5 != '' &&
                            data.custom_field_6 != '' &&
                            data.custom_field_7 != '' &&
                            data.custom_field_8 != '')
                    ) {
                        get_invoice_id.push(data.common_invoice)
                    }
                }
            })
            if (check_hasshipping) {
                handleRedirectInternal(history, `checkout/auction?id=${invoiceId.join('&id=')}`)
            } else {
                if (no_call_api == 1) {
                    if (get_invoice_id.length > 0) {
                        check_get_shipping_rate_api({
                            invoiceID: get_invoice_id,
                            toPostalCode: user.zip,
                            fromPostalCode: seller_zip ? seller_zip : seller_zipcode,
                            toCountry: user.country,
                        })
                    } else {
                        handleRedirectInternal(
                            history,
                            `checkout/auction?id=${invoiceId.join('&id=')}`,
                        )
                    }
                } else {
                    setAlert(
                        'Your Selected Items Sellers Are Different.We are unable to update shippingcost.please select same sellers item and checkout',
                        'error',
                    )
                    return false
                }
            }
        } else {
            handleRedirectInternal(history, `checkout/auction?id=${invoiceId.join('&id=')}`)
        }
    }

    useEffect(() => {
        if (shipping_rate_calculation.status) {
            if (shipping_rate_calculation.status == 'success') {
                if (shipping_rate_calculation.data?.message?.shipping_error == '') {
                    handleRedirectInternal(history, `checkout/auction?id=${invoiceId.join('&id=')}`)
                } else {
                    if (shipping_rate_calculation.data?.message?.shipping_status == 500) {
                        setAlert(
                            'Product dimensional or Zip Code incorrect.Please check your Zip Code or contact seller.',
                            'error',
                        )
                    } else {
                        setAlert('Please Try Again Later!', 'error')
                    }
                }
            } else {
                setAlert('Please Try Again Later!', 'error')
            }
            clear_ship_station_amount()
        }
    }, [shipping_rate_calculation])
    const getBidText = (data) => {
        if (isAuthenticated && data.latestbid != null && user?.id != undefined) {
            if (data.auction) {
                if (
                    (data.highbid || data.highest_bidder_user_id == user?.id) &&
                    data.latestbid >= data.rprice
                ) {
                    if (data.market_status == 'open') {
                        return 'Winning'
                    } else if (data.market_status == 'sold') {
                        return 'Won'
                    }
                } else if (!data.highbid || data.highest_bidder_user_id != user?.id) {
                    if (data.market_status == 'open') {
                        return 'Outbid'
                    } else if (data.market_status == 'sold') {
                        return 'Lost'
                    }
                } else {
                    return ''
                }
            }
        }
    }

    const makemarkasrecived = (data) => {
        var send_data = {
            invoice_id: data.common_invoice,
            site_id: global?.storeDetails?.site_id ? global.storeDetails.site_id : '',
        }
        markasrecived(send_data)
    }

    const sortbyauction = (event) => {
        setLotof(event.target.value)
        var send_data = search
        send_data.lotof = parseInt(event.target.value)
        getAllBuyerProducts(send_data)
    }

    return (
        <>
            <div className="row mb-3">
                {props.searchbar ? (
                    <div className="col-12 col-md-4 col-sm-6">
                        <form className="nav-standard-top-area-search">
                            <div className="nav-standard-top-area-search-inner">
                                <input
                                    type="text"
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    name="searchValue"
                                    value={searchValue}
                                    className="nav-standard-top-area-search-input open-sans onlyLotPlace"
                                    placeholder={t('lot_id') + '/ ' + t('title') + '/ Invoice Id'}
                                />
                                <button
                                    onClick={handleSearch}
                                    className="lin lin-magnifier subLotSearch nav-standard-top-area-search-sumbit onlyLotSearch"
                                    type={t('submit')}
                                >
                                    <span className="material-icons">search</span>
                                </button>
                            </div>
                        </form>
                    </div>
                ) : null}

                {global?.storeConfigration?.new_schedule_pickup?.value == 1 &&
                (props.page === 'bidwon' || props.page === 'buynow') ? (
                    scheduleLength ? (
                        <div className="col-12 col-md-5 col-sm-6 ml-auto schedule-pickup-btn">
                            {props.sch_type == 1 && (
                                <MultiPickup
                                    invoice={{
                                        site_id: global?.storeDetails?.site_id,
                                        common_invoice: scheduleInvoiceId,
                                        date_closed: date_closed,
                                        paid_date: paid_date,
                                    }}
                                    pickupData={{}}
                                    auctionData={{
                                        location_id: Items.find(
                                            (val) => val.common_invoice === scheduleInvoiceId[0],
                                        )?.location_id,
                                    }}
                                    sellerData={{
                                        seller_name: global?.storeDetails?.name,
                                        email: global?.storeDetails?.contact_email,
                                    }}
                                    trigger={trigger}
                                    setTrigger={setTrigger}
                                    sch_type={props.sch_type}
                                />
                            )}
                            {props.sch_type == 2 &&
                                global.storeConfigration?.new_schedule_pickup_2.value !== '1' && (
                                    <MultiPickup
                                        invoice={{
                                            site_id: global?.storeDetails?.site_id,
                                            id: scheduleInvoiceId,
                                            schedule_location_id: Items.find(
                                                (val) => val.scheduled === scheduleInvoiceId[0],
                                            )?.schedule_location_id,
                                            common_invoice: Items.filter(
                                                (val) => val.scheduled === scheduleInvoiceId[0],
                                            ).map((val) => val.common_invoice),
                                            date_closed: date_closed,
                                            paid_date: paid_date,
                                        }}
                                        pickupData={{}}
                                        auctionData={{
                                            location_id: Items.find(
                                                (val) => val.scheduled === scheduleInvoiceId[0],
                                            )?.location_id,
                                        }}
                                        sellerData={{
                                            seller_name: global?.storeDetails?.name,
                                            email: global?.storeDetails?.contact_email,
                                        }}
                                        trigger={trigger}
                                        setTrigger={setTrigger}
                                        sch_type={props.sch_type}
                                    />
                                )}
                        </div>
                    ) : null
                ) : null}

                {((props.page === 'bidwon' && props.checkout_but_pos != 'top') ||
                    (props.page === 'make_an_offer' && props.checkout_but_pos != 'top')) &&
                props.sch_type != 1 &&
                props.sch_type != 2
                    ? props.abandoned
                        ? null
                        : props.storeTheme == 'Lotsofauctions' && (
                              <div className="col-12 col-md-3 col-sm-6 ml-auto checkout-button">
                                  <SecondaryButton
                                      label={`Checkout (${checkoutLength})`}
                                      className="mbv-btn"
                                      onClick={() => redirect_checkout()}
                                      disabled={!checkoutLength}
                                  />
                              </div>
                          )
                    : null}
            </div>

            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : (props.abandoned ? abandonedOrders.products.length > 0 : Items?.length > 0) ? (
                <>
                    {(props.page === 'bidwon' || props.page === 'make_an_offer') &&
                    props.checkout_but_pos == 'top' &&
                    props.sch_type != 1 &&
                    props.sch_type != 2 &&
                    !global.storeConfigration?.auction_dashboard_with_filter?.value ? (
                        <div className="row align-items-center mb-3">
                            <div className="col-md-2 col-sm-2"></div>
                            <div className="col-md-6 col-sm-6">
                                <p className="mb-0" style={{ color: 'red' }}>
                                    * {t('please_click_chec')}.
                                </p>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <PrimaryButton
                                    label={`${t('checkout')} (${invoiceId.length})`}
                                    className="mbv-btn"
                                    onClick={() => redirect_checkout()}
                                    disabled={!invoiceId.length}
                                />
                            </div>
                        </div>
                    ) : null}
                    {console.log(props.page, 'fgt')}
                    {props.abandoned ? (
                        ''
                    ) : global.storeConfigration?.auction_dashboard_with_filter?.value ? (
                        <div className="row align-items-center mb-3">
                            {props.page === 'bidwon' ? (
                                <>
                                    {props.schedule ? (
                                        ''
                                    ) : (
                                        <div className="col-md-6 col-sm-4">
                                            <p className="mb-0" style={{ color: 'red' }}>
                                                * {t('please_click_chec')}.
                                            </p>
                                        </div>
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                            {props.page === 'buynow' ? null : (
                                <div className="col-md-2 col-sm-4 auctionTit">
                                    <CustomSelect
                                        label="Auction Title"
                                        value={lotof}
                                        size="small"
                                        selectType="noBorder"
                                        name="lotof"
                                        onChange={(event, editor) => sortbyauction(event)}
                                    >
                                        <option value={''}>All Auctions</option>
                                        {auction_list.map((opt, optindex) => (
                                            <option value={opt.id}>{opt.title}</option>
                                        ))}
                                    </CustomSelect>
                                </div>
                            )}

                            {props.page === 'bidwon' ? (
                                <>
                                    {props.schedule ? (
                                        ''
                                    ) : (
                                        <div className="col-md-4 col-sm-4">
                                            <PrimaryButton
                                                label={`${t('checkout')} (${invoiceId.length})`}
                                                className="mbv-btn"
                                                onClick={() => redirect_checkout()}
                                                disabled={!invoiceId.length}
                                            />
                                        </div>
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                    {props.page === 'bidactive' &&
                    global?.storeConfigration?.show_user_bid_count?.value == 1 &&
                    buyer_allproducts.user_bid_count_data ? (
                        <>
                            <div className="table-responsive">
                                <table className="table table-hover mobile-table">
                                    <thead className="hidden-mobile">
                                        <tr>
                                            <th></th>
                                            <th>Winning Max Bids</th>
                                            <th>Winning Bids</th>
                                            <th>Losing Bids</th>
                                            <th>Your Total Bids</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td className="ml-data top-90">Total(With BP)</td>
                                            <td className="ml-data top-90">
                                                $
                                                {
                                                    buyer_allproducts.user_bid_count_data
                                                        .winning_max_bid
                                                }{' '}
                                                $
                                                {
                                                    buyer_allproducts.user_bid_count_data
                                                        .winning_max_bid_with_bp
                                                }
                                            </td>
                                            <td className="ml-data top-90">
                                                ${buyer_allproducts.user_bid_count_data.winning_bid}{' '}
                                                $
                                                {
                                                    buyer_allproducts.user_bid_count_data
                                                        .winning_bid_with_bp
                                                }
                                            </td>
                                            <td className="ml-data top-90">
                                                ${buyer_allproducts.user_bid_count_data.losting_bid}{' '}
                                                $
                                                {
                                                    buyer_allproducts.user_bid_count_data
                                                        .losting_bid_with_bp
                                                }
                                            </td>
                                            <td className="ml-data top-90">
                                                ${buyer_allproducts.user_bid_count_data.total_bid} $
                                                {
                                                    buyer_allproducts.user_bid_count_data
                                                        .total_bid_with_bp
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : null}

                    <div className="table-responsive">
                        {!props.gridView && (
                            <table className="table table-hover mobile-table">
                                <thead className="hidden-mobile">
                                    <tr>
                                        {props.page === 'bidactive' ? (
                                            <>
                                                {global?.storeConfigration?.lot_sequence_order
                                                    ?.value == 1 ? (
                                                    <th className="numCell">Lot #</th>
                                                ) : (
                                                    <th className="numCell">S.no</th>
                                                )}
                                                <th>{t('Image')}</th>
                                                <th>{t('name')}</th>
                                                <th>{t('time_left')}</th>
                                                <th>{t('my_b')}</th>
                                                {!props.noProxy && <th>Proxy Bid</th>}
                                                <th>{t('current_prc')}</th>
                                                <th>{t('Status')}</th>
                                                <th className="text-center">{t('action')}</th>
                                            </>
                                        ) : props.page === 'bidlost' ? (
                                            <>
                                                {global?.storeConfigration?.lot_sequence_order
                                                    ?.value == 1 ? (
                                                    <th className="numCell">Lot #</th>
                                                ) : (
                                                    <th className="numCell">S.no</th>
                                                )}
                                                <th>{t('Image')}</th>
                                                <th>{t('name')}</th>
                                                <th>{t('date_closed')}</th>
                                                <th>{t('winning_bid')}</th>
                                            </>
                                        ) : props.page === 'bidwon' ? (
                                            props.abandoned ? (
                                                <>
                                                    <th className="numCell text-nowrap">
                                                        Invoice No
                                                    </th>
                                                    <th>{t('Image')}</th>
                                                    <th>{t('name')}</th>
                                                    <th className="text-nowrap">
                                                        {t('Abandoned Date')}
                                                    </th>
                                                    <th>{t('status')}</th>
                                                </>
                                            ) : (
                                                <>
                                                    <th className="numCell">Invoice No</th>
                                                    {global?.storeConfigration?.lot_sequence_order
                                                        ?.value == 1 ? (
                                                        <th className="numCell">Lot #</th>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <th>{t('Image')}</th>
                                                    <th>{t('name')}</th>
                                                    {props.pickupCode ? <th>Pickup Code</th> : null}
                                                    {global.pluginConfiguration?.schedule_pickup
                                                        ?.enable == 1 && props.sch_type != 1 ? (
                                                        <th>Pickup Date</th>
                                                    ) : null}
                                                    {(props.sch_type == 2 || props.sch_type == 1) &&
                                                    global?.storeConfigration?.new_schedule_pickup
                                                        ?.value == 1 ? (
                                                        <>
                                                            <th>Warehouse Location</th>
                                                            {props.sch_type != 1 ? (
                                                                <th>Pickup Status</th>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </>
                                                    ) : null}
                                                    <th>{t('date_closed')}</th>
                                                    <th>{t('winning_bid')}</th>
                                                    <th>{t('status')}</th>
                                                    {global.pluginConfiguration?.partial_payment
                                                        ?.enable == 1 ? (
                                                        <th>Due Amount</th>
                                                    ) : null}
                                                    <th className="text-center">
                                                        {props.shippingauction
                                                            ? 'Shipping Action'
                                                            : t('action')}
                                                    </th>
                                                    {global?.pluginConfiguration
                                                        ?.flutterwave_payment_gateway?.enable ==
                                                    1 ? (
                                                        <th>{t('Received')}</th>
                                                    ) : null}
                                                    {global?.pluginConfiguration?.feedback
                                                        ?.enable == 1 ? (
                                                        <th>{t('Feedback')}</th>
                                                    ) : null}
                                                    {global?.pluginConfiguration?.disputes
                                                        ?.enable == 1 ? (
                                                        <th>{t('Dispute')}</th>
                                                    ) : null}
                                                    {global?.pluginConfiguration?.refund?.enable ==
                                                        1 &&
                                                    global.storeConfigration?.enable_request_refund
                                                        ?.value == 1 ? (
                                                        <>
                                                            <th>{t('Return')}</th>
                                                            <th>{t('Status')}</th>
                                                        </>
                                                    ) : null}
                                                </>
                                            )
                                        ) : props.page === 'buynow' ? (
                                            <>
                                                <th className="numCell">Invoice No</th>
                                                {global?.storeConfigration?.lot_sequence_order
                                                    ?.value == 1 ? (
                                                    <th className="numCell">Lot #</th>
                                                ) : (
                                                    ''
                                                )}
                                                <th>{t('Image')}</th>
                                                <th>{t('name')}</th>
                                                {global?.storeConfigration?.unique_slot4?.value ==
                                                1 ? (
                                                    <th>Uploads</th>
                                                ) : null}
                                                {props.pickupCode ? <th>Pickup Code</th> : null}
                                                {global.pluginConfiguration?.schedule_pickup
                                                    ?.enable == 1 && props.sch_type != 1 ? (
                                                    <th>Pickup Date</th>
                                                ) : null}
                                                {(props.sch_type == 2 || props.sch_type == 1) &&
                                                global?.storeConfigration?.new_schedule_pickup
                                                    ?.value == 1 ? (
                                                    <>
                                                        <th>Warehouse Location</th>
                                                        {props.sch_type != 1 ? (
                                                            <th>Pickup Status</th>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </>
                                                ) : null}
                                                <th>{t('date_purchased')}</th>
                                                <th>{t('price')}</th>

                                                <th className="text-center">
                                                    {props.shippingauction
                                                        ? 'Shipping Action'
                                                        : t('action')}
                                                </th>
                                                {global?.pluginConfiguration
                                                    ?.flutterwave_payment_gateway?.enable == 1 ? (
                                                    <th>{t('Received')}</th>
                                                ) : null}
                                                {global?.pluginConfiguration?.feedback?.enable ==
                                                    1 &&
                                                global?.storeConfigration?.unique_slot4?.value !=
                                                    1 ? (
                                                    <th>{t('Feedback')}</th>
                                                ) : null}
                                                {global?.pluginConfiguration?.disputes?.enable ==
                                                1 ? (
                                                    <th>{t('Dispute')}</th>
                                                ) : null}
                                                {global?.pluginConfiguration?.refund?.enable == 1 &&
                                                global.storeConfigration?.enable_request_refund
                                                    ?.value == 1 ? (
                                                    <>
                                                        <th>{t('Return')}</th>
                                                        <th>{t('Return Status')}</th>
                                                    </>
                                                ) : null}
                                            </>
                                        ) : props.page === 'make_an_offer' ? (
                                            <>
                                                {global?.storeConfigration?.lot_sequence_order
                                                    ?.value == 1 ? (
                                                    <th className="numCell">Lot #</th>
                                                ) : (
                                                    <th className="numCell">S.no</th>
                                                )}
                                                <th>{t('Image')}</th>
                                                <th>Name</th>
                                                {props.pickupCode ? <th>Pickup Code</th> : null}
                                                {global.pluginConfiguration?.schedule_pickup
                                                    ?.enable == 1 ? (
                                                    <th>Pickup Date</th>
                                                ) : null}
                                                <th>Date Requested</th>
                                                <th>Price</th>
                                                <th className="text-center">
                                                    {props.shippingauction
                                                        ? 'Shipping Action'
                                                        : 'Action'}
                                                </th>
                                                {global?.pluginConfiguration?.disputes?.enable ==
                                                1 ? (
                                                    <th>{t('Dispute')}</th>
                                                ) : null}
                                                {global?.pluginConfiguration?.refund?.enable == 1 &&
                                                global.storeConfigration?.enable_request_refund
                                                    ?.value == 1 ? (
                                                    <>
                                                        <th>{t('Return')}</th>
                                                        <th>{t('Return Status')}</th>
                                                    </>
                                                ) : null}
                                            </>
                                        ) : null}
                                    </tr>
                                </thead>

                                <tbody>
                                    {props.abandoned ? (
                                        abandonedOrders.products.length > 0 ? (
                                            abandonedOrders.products.map((order, index) => {
                                                return (
                                                    <>
                                                        {order ? (
                                                            <tr key={index}>
                                                                <td className="ml-data top-90">
                                                                    {order?.invoice_id}
                                                                </td>
                                                                <td className="ml-data">
                                                                    <LazyLoadImage
                                                                        effect="blur"
                                                                        placeholderSrc="/assets/svg/imageLoading.svg"
                                                                        src={`${
                                                                            order?.content_head1 ===
                                                                                '0' ||
                                                                            order?.store_id === 0
                                                                                ? process.env
                                                                                      .REACT_APP_BASE_URL +
                                                                                  'uploads/product/'
                                                                                : process.env
                                                                                      .REACT_APP_PRODUCT_IMAGE_URL
                                                                        }${
                                                                            //   order.file_name ||
                                                                            //   (Array.isArray(
                                                                            //       order.lotImages,
                                                                            //   ) &&
                                                                            //       order.lotImages?.length &&
                                                                            //       order.lotImages[0]
                                                                            //           ?.file_name) ||
                                                                            //   order.avatar_vc ||
                                                                            order.avatar
                                                                        }`}
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        width="65"
                                                                        onError={(e) =>
                                                                            (e.target.src =
                                                                                imageError())
                                                                        }
                                                                    />
                                                                </td>
                                                                <td
                                                                    data-label="Title:"
                                                                    className="mb-descp"
                                                                >
                                                                    <div
                                                                        className="pdtNmClmn"
                                                                        title={order?.title}
                                                                    >
                                                                        {order?.title}
                                                                    </div>
                                                                </td>
                                                                <td className="mb-price-td">
                                                                    <span className="hide-web">
                                                                        {t('date_added')}
                                                                    </span>
                                                                    <span className="bwDc">
                                                                        {order?.date_added
                                                                            ? dateFormatFrontDay(
                                                                                  order?.date_added,
                                                                              )
                                                                            : '-'}
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign: 'left',
                                                                        fontWeight: '700',
                                                                        textTransform: 'uppercase',
                                                                    }}
                                                                >
                                                                    {order?.status == 1
                                                                        ? `Abandoned ${
                                                                              order?.type == 1
                                                                                  ? 'Unpaid'
                                                                                  : ''
                                                                          }`
                                                                        : '-'}
                                                                </td>
                                                            </tr>
                                                        ) : null}
                                                    </>
                                                )
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={5}>
                                                    <NoRecordsFound />
                                                </td>
                                            </tr>
                                        )
                                    ) : (
                                        Items.length !== 0 &&
                                        Items.map((item, index) => {
                                            return (
                                                <>
                                                    {item ? (
                                                        <tr key={index}>
                                                            {props.page === 'make_an_offer' ? (
                                                                ''
                                                            ) : (
                                                                <td className="ml-data top-90 schedulePickBeach">
                                                                    {props.sch_type == 1 &&
                                                                    global?.storeConfigration
                                                                        ?.new_schedule_pickup
                                                                        ?.value == 1 &&
                                                                    item.buynowpaid &&
                                                                    item.localpickup == 1 &&
                                                                    !item.scheduled ? (
                                                                        <CheckBox
                                                                            checked={scheduleInvoiceId.includes(
                                                                                item.common_invoice,
                                                                            )}
                                                                            name="schedule"
                                                                            label=""
                                                                            onChange={(e) =>
                                                                                handleScheduleInvoiceChange(
                                                                                    item.common_invoice,
                                                                                    item.location_id,
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                item.scheduled
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                    {/*props.sch_type == 2 &&
                                                                    global?.storeConfigration
                                                                        ?.new_schedule_pickup
                                                                        ?.value == 1 &&
                                                                    item.buynowpaid &&
                                                                    item.localpickup == 1 &&
                                                                    item.scheduled &&
                                                                    !item.car_make &&
                                                                    item.status != 'completed' ? (
                                                                        <CheckBox
                                                                            checked={scheduleInvoiceId.includes(
                                                                                item.scheduled,
                                                                            )}
                                                                            name="schedule"
                                                                            label=""
                                                                            onChange={(e) =>
                                                                                handleScheduleCheckinChange(
                                                                                    item.scheduled,
                                                                                    item.location_id,
                                                                                )
                                                                            }
                                                                            disabled={item.car_make}
                                                                        />
                                                                        ) : null*/}
                                                                    <a
                                                                        href="#"
                                                                        onClick={() =>
                                                                            global.storeDetails
                                                                                ?.theme === 9
                                                                                ? history.push({
                                                                                      pathname: `/productView/${item.id}`,
                                                                                      state: {
                                                                                          lotid: [],
                                                                                      },
                                                                                  })
                                                                                : props.redirect_product_view
                                                                                ? handleRedirectInternal(
                                                                                      history,
                                                                                      props.theme ===
                                                                                          'auctioneer'
                                                                                          ? `productview/${
                                                                                                item.id
                                                                                            }/${
                                                                                                item.auction
                                                                                                    ? 'auction'
                                                                                                    : 'buynow'
                                                                                            }/${
                                                                                                (item?.custom_field_9?.includes(
                                                                                                    '}',
                                                                                                )
                                                                                                    ? JSON.parse(
                                                                                                          item.custom_field_9,
                                                                                                      )
                                                                                                    : {}
                                                                                                )?.game_theme
                                                                                                    ?.toLowerCase()
                                                                                                    ?.split(
                                                                                                        ' ',
                                                                                                    )
                                                                                                    ?.join(
                                                                                                        '-',
                                                                                                    ) +
                                                                                                '-' +
                                                                                                item?.subcategory
                                                                                                    ?.split(
                                                                                                        ',',
                                                                                                    )
                                                                                                    ?.map(
                                                                                                        (
                                                                                                            val,
                                                                                                        ) =>
                                                                                                            categoryTypes[
                                                                                                                val
                                                                                                            ]?.description?.toLowerCase() ??
                                                                                                            '',
                                                                                                    )
                                                                                                    ?.join(
                                                                                                        '-',
                                                                                                    )
                                                                                                    .split(
                                                                                                        ' ',
                                                                                                    )
                                                                                                    .join(
                                                                                                        '-',
                                                                                                    )
                                                                                            }`
                                                                                          : `productView/${item.id}`,
                                                                                  )
                                                                                : handleClick(
                                                                                      item.id,
                                                                                  )
                                                                        }
                                                                        className="tb-link"
                                                                    >
                                                                        {(props.page ==
                                                                            'bidactive' ||
                                                                            props.page ==
                                                                                'bidlost') &&
                                                                        global?.storeConfigration
                                                                            ?.lot_sequence_order
                                                                            ?.value == 1
                                                                            ? item.lot_number
                                                                                ? item.lot_number
                                                                                : '-'
                                                                            : item.common_invoice &&
                                                                              props.page !==
                                                                                  'make_an_offer'
                                                                            ? item.common_invoice
                                                                            : index + 1}
                                                                    </a>
                                                                </td>
                                                            )}
                                                            {global?.storeConfigration
                                                                ?.lot_sequence_order?.value == 1 &&
                                                            props.page != 'bidactive' &&
                                                            props.page != 'bidlost' ? (
                                                                <td>
                                                                    {item.lot_number
                                                                        ? item.lot_number
                                                                        : '-'}
                                                                </td>
                                                            ) : (
                                                                ''
                                                            )}
                                                            <td className="ml-data">
                                                                <LazyLoadImage
                                                                    effect="blur"
                                                                    placeholderSrc="/assets/svg/imageLoading.svg"
                                                                    src={`${
                                                                        item?.content_head1 ===
                                                                            '0' ||
                                                                        item?.store_id === 0
                                                                            ? process.env
                                                                                  .REACT_APP_BASE_URL +
                                                                              'uploads/product/'
                                                                            : process.env
                                                                                  .REACT_APP_PRODUCT_IMAGE_URL
                                                                    }${
                                                                        item.file_name ||
                                                                        (Array.isArray(
                                                                            item.lotImages,
                                                                        ) &&
                                                                            item.lotImages
                                                                                ?.length &&
                                                                            item.lotImages[0]
                                                                                ?.file_name) ||
                                                                        item.avatar_vc ||
                                                                        item.avatar
                                                                    }`}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    width="65"
                                                                    onClick={() =>
                                                                        global.storeDetails
                                                                            ?.theme === 9
                                                                            ? history.push({
                                                                                  pathname: `/productView/${item.id}`,
                                                                                  state: {
                                                                                      lotid: [],
                                                                                  },
                                                                              })
                                                                            : props.redirect_product_view
                                                                            ? handleRedirectInternal(
                                                                                  history,
                                                                                  props.theme ===
                                                                                      'auctioneer'
                                                                                      ? `productview/${
                                                                                            item.id
                                                                                        }/${
                                                                                            item.auction
                                                                                                ? 'auction'
                                                                                                : 'buynow'
                                                                                        }/${
                                                                                            (item?.custom_field_9?.includes(
                                                                                                '}',
                                                                                            )
                                                                                                ? JSON.parse(
                                                                                                      item.custom_field_9,
                                                                                                  )
                                                                                                : {}
                                                                                            )?.game_theme
                                                                                                ?.toLowerCase()
                                                                                                ?.split(
                                                                                                    ' ',
                                                                                                )
                                                                                                ?.join(
                                                                                                    '-',
                                                                                                ) +
                                                                                            '-' +
                                                                                            item?.subcategory
                                                                                                ?.split(
                                                                                                    ',',
                                                                                                )
                                                                                                ?.map(
                                                                                                    (
                                                                                                        val,
                                                                                                    ) =>
                                                                                                        categoryTypes[
                                                                                                            val
                                                                                                        ]?.description?.toLowerCase() ??
                                                                                                        '',
                                                                                                )
                                                                                                ?.join(
                                                                                                    '-',
                                                                                                )
                                                                                                .split(
                                                                                                    ' ',
                                                                                                )
                                                                                                .join(
                                                                                                    '-',
                                                                                                )
                                                                                        }`
                                                                                      : `productView/${item.id}`,
                                                                              )
                                                                            : handleClick(item.id)
                                                                    }
                                                                    onError={(e) =>
                                                                        (e.target.src =
                                                                            imageError())
                                                                    }
                                                                />
                                                                {/* {item.avatar != '' &&
                                                            item.avatar != undefined ? (
                                                                <img
                                                                    src={getImageurl(
                                                                        item.content_head1,
                                                                        item.avatar,
                                                                    )}
                                                                    alt=""
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    width="65"
                                                                    onClick={() =>
                                                                        handleClick(item.id)
                                                                    }
                                                                    onError={(e) =>
                                                                        (e.target.src =
                                                                            imageError())
                                                                    }
                                                                />
                                                            ) : item.productImagesName != '' ? (
                                                                <img
                                                                    src={getFilePath(
                                                                        item.productImagesName,
                                                                        item.auctionlot_title,
                                                                        true,
                                                                    )}
                                                                    alt=""
                                                                    onError={(e) =>
                                                                        (e.target.src =
                                                                            `${global?.storeDetails?.logoValue}`)
                                                                    }
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                        handleClick(item.id)
                                                                    }
                                                                    width="65"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={imageError()}
                                                                    alt=""
                                                                    width="65"
                                                                    className="pro-img img-fluid"
                                                                />
                                                            )} */}
                                                            </td>
                                                            <td
                                                                data-label="Title:"
                                                                className="mb-descp"
                                                            >
                                                                <div
                                                                    className="pdtNmClmn"
                                                                    title={item.title}
                                                                >
                                                                    <span className="hide-web">
                                                                        {t('name')}:
                                                                    </span>
                                                                    {item.title}
                                                                    <br />
                                                                    {item.contact_seller}
                                                                </div>
                                                            </td>
                                                            {global?.storeConfigration?.unique_slot4
                                                                ?.value == 1 &&
                                                            props.page === 'buynow' ? (
                                                                <td>
                                                                    <SecondaryButton
                                                                        btnSize="minMaxContent"
                                                                        onClick={(e) => {
                                                                            handleSeeVideo(item)
                                                                        }}
                                                                    >
                                                                        See Video{' '}
                                                                        {item.msg_count ? (
                                                                            <Chip
                                                                                className="msgCnt ml-2"
                                                                                label={
                                                                                    item.msg_count
                                                                                }
                                                                                size="small"
                                                                            />
                                                                        ) : null}
                                                                    </SecondaryButton>
                                                                </td>
                                                            ) : null}
                                                            {props.pickupCode &&
                                                            (props.page === 'bidwon' ||
                                                                props.page === 'buynow' ||
                                                                props.page === 'make_an_offer') ? (
                                                                <>
                                                                    <td>
                                                                        <span className="hide-web">
                                                                            Pickup Code
                                                                        </span>
                                                                        {item.transfer_otp}
                                                                    </td>
                                                                </>
                                                            ) : null}
                                                            {global.pluginConfiguration
                                                                ?.schedule_pickup?.enable == 1 &&
                                                            props.sch_type != 1 &&
                                                            (props.page === 'bidwon' ||
                                                                props.page === 'buynow' ||
                                                                props.page === 'make_an_offer') ? (
                                                                <>
                                                                    <td className="onlyPick">
                                                                        <span className="hide-web">
                                                                            Pickup Date
                                                                        </span>
                                                                        {item.sche_localpick_local
                                                                            ? moment(
                                                                                  item.sche_localpick_local,
                                                                              ).format(
                                                                                  'YYYY-MM-DD hh:mm A',
                                                                              )
                                                                            : '-'}
                                                                    </td>
                                                                </>
                                                            ) : null}
                                                            {(props.page === 'bidwon' ||
                                                                props.page === 'buynow') &&
                                                            (props.sch_type == 2 ||
                                                                props.sch_type == 1) &&
                                                            global?.storeConfigration
                                                                ?.new_schedule_pickup?.value ==
                                                                1 ? (
                                                                <>
                                                                    <td className="mb-price-td">
                                                                        <span className="hide-web">
                                                                            Warehouse Location
                                                                        </span>
                                                                        {item.schedule_location_name ||
                                                                            item.location_name ||
                                                                            '-'}
                                                                    </td>
                                                                    {props.sch_type != 1 ? (
                                                                        <td>
                                                                            {item.status || '-'}
                                                                        </td>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            ) : null}
                                                            {props.page === 'bidactive' ? (
                                                                <>
                                                                    <td className="text-nowrap">
                                                                        <Timer
                                                                            date_added={
                                                                                item.date_added
                                                                            }
                                                                            date_closed={
                                                                                item.date_closed
                                                                            }
                                                                            withText={1}
                                                                            endText={
                                                                                item.con_check == 1
                                                                                    ? 'Auction Started'
                                                                                    : 'Ends in' +
                                                                                      ': '
                                                                            }
                                                                            startText={
                                                                                'Starts in' + ': '
                                                                            }
                                                                            live_auction={
                                                                                item.con_check
                                                                            }
                                                                        ></Timer>
                                                                    </td>
                                                                    <td
                                                                        data-label="My Bid: "
                                                                        className="mb-price-td"
                                                                    >
                                                                        <span className="hide-web">
                                                                            {t('my_b')}:
                                                                        </span>
                                                                        <span className="crncyValueinDb">
                                                                            {currencyFormat(
                                                                                item.mybid
                                                                                    ? item.mybid
                                                                                    : 0,
                                                                            )}
                                                                        </span>
                                                                        <span className="crncyValueinDb">
                                                                            <CurrencyConverterFormat
                                                                                amount={
                                                                                    item.mybid
                                                                                        ? item.mybid
                                                                                        : 0
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                    {/*eslint-disable*/}
                                                                    {item.mybid !==
                                                                    item.maxbidamt ? (
                                                                        <td
                                                                            data-label="Proxy Bid: "
                                                                            className="mb-price-td"
                                                                        >
                                                                            {!props.noProxy && (
                                                                                <span className="hide-web">
                                                                                    Proxy Bid
                                                                                </span>
                                                                            )}
                                                                            <span className="pxyBdVl">
                                                                                {currencyFormat(
                                                                                    item.maxbidamt
                                                                                        ? item.maxbidamt
                                                                                        : 0,
                                                                                    props.currency,
                                                                                )}
                                                                                <CurrencyConverterFormat
                                                                                    amount={
                                                                                        item.maxbidamt
                                                                                            ? item.maxbidamt
                                                                                            : 0
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        </td>
                                                                    ) : !props.noProxy ? (
                                                                        <td>-</td>
                                                                    ) : (
                                                                        ''
                                                                    )}

                                                                    <td
                                                                        className="text-nowrap mb-price-td"
                                                                        data-label="Current Bid: "
                                                                    >
                                                                        <span className="hide-web">
                                                                            {t('current_prc')}:
                                                                        </span>
                                                                        {currencyFormat(
                                                                            item.latestbid
                                                                                ? item.latestbid
                                                                                : 0,
                                                                        )}
                                                                        <CurrencyConverterFormat
                                                                            amount={
                                                                                item.latestbid
                                                                                    ? item.latestbid
                                                                                    : 0
                                                                            }
                                                                        />
                                                                    </td>

                                                                    {props.page === 'bidactive' ? (
                                                                        <td
                                                                            className={`text-md-center text-left ${`badgeStatus ${getBidText(
                                                                                item,
                                                                            )}`}`}
                                                                        >
                                                                            {item?.bidtopstatus?.includes(
                                                                                'won',
                                                                            )
                                                                                ? 'Won'
                                                                                : item?.bidtopstatus?.includes(
                                                                                      'lost',
                                                                                  )
                                                                                ? 'Lost'
                                                                                : item?.bidtopstatus?.includes(
                                                                                      'winning',
                                                                                  )
                                                                                ? 'Winning'
                                                                                : item?.bidtopstatus?.includes(
                                                                                      'outbid',
                                                                                  )
                                                                                ? 'Outbid'
                                                                                : getBidText(item)}
                                                                        </td>
                                                                    ) : null}

                                                                    <>
                                                                        {item.updateBid ? (
                                                                            <td>
                                                                                <a className="ub-btn">
                                                                                    Update Bid
                                                                                </a>
                                                                            </td>
                                                                        ) : (
                                                                            <td>
                                                                                {item.con_check ==
                                                                                1 ? null : (
                                                                                    <PrimaryButton
                                                                                        label={t(
                                                                                            'view_lot',
                                                                                        )}
                                                                                        btnSize="mb-2"
                                                                                        onClick={() =>
                                                                                            global
                                                                                                .storeDetails
                                                                                                ?.theme ===
                                                                                            9
                                                                                                ? history.push(
                                                                                                      {
                                                                                                          pathname: `/productView/${item.id}`,
                                                                                                          state: {
                                                                                                              lotid: [],
                                                                                                          },
                                                                                                      },
                                                                                                  )
                                                                                                : props.redirect_product_view
                                                                                                ? handleRedirectInternal(
                                                                                                      history,
                                                                                                      props.theme ===
                                                                                                          'auctioneer'
                                                                                                          ? `productview/${
                                                                                                                item.id
                                                                                                            }/${
                                                                                                                item.auction
                                                                                                                    ? 'auction'
                                                                                                                    : 'buynow'
                                                                                                            }/${
                                                                                                                (item?.custom_field_9?.includes(
                                                                                                                    '}',
                                                                                                                )
                                                                                                                    ? JSON.parse(
                                                                                                                          item.custom_field_9,
                                                                                                                      )
                                                                                                                    : {}
                                                                                                                )?.game_theme
                                                                                                                    ?.toLowerCase()
                                                                                                                    ?.split(
                                                                                                                        ' ',
                                                                                                                    )
                                                                                                                    ?.join(
                                                                                                                        '-',
                                                                                                                    ) +
                                                                                                                '-' +
                                                                                                                item?.subcategory
                                                                                                                    ?.split(
                                                                                                                        ',',
                                                                                                                    )
                                                                                                                    ?.map(
                                                                                                                        (
                                                                                                                            val,
                                                                                                                        ) =>
                                                                                                                            categoryTypes[
                                                                                                                                val
                                                                                                                            ]?.description?.toLowerCase() ??
                                                                                                                            '',
                                                                                                                    )
                                                                                                                    ?.join(
                                                                                                                        '-',
                                                                                                                    )
                                                                                                                    .split(
                                                                                                                        ' ',
                                                                                                                    )
                                                                                                                    .join(
                                                                                                                        '-',
                                                                                                                    )
                                                                                                            }`
                                                                                                          : `productView/${item.id}`,
                                                                                                  )
                                                                                                : handleClick(
                                                                                                      item.id,
                                                                                                  )
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                {props.noView !==
                                                                                    true && (
                                                                                    <>
                                                                                        {item.lotof && (
                                                                                            <PrimaryButton
                                                                                                label="View Auction"
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        global
                                                                                                            .storeConfigration
                                                                                                            ?.auction_dashboard_with_filter
                                                                                                            ?.value
                                                                                                    ) {
                                                                                                        localStorage.setItem(
                                                                                                            'lotof',
                                                                                                            lotof,
                                                                                                        )
                                                                                                    }

                                                                                                    handleRedirectInternal(
                                                                                                        history,
                                                                                                        `${
                                                                                                            item.con_check ==
                                                                                                            1
                                                                                                                ? 'liveauctionview'
                                                                                                                : 'search/product-buyer-auction'
                                                                                                        }/${Number(
                                                                                                            item.lotof,
                                                                                                        )}`,
                                                                                                    )
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                <RetractBid
                                                                                    enableRetractBid={
                                                                                        props.enableRetractBid
                                                                                    }
                                                                                    user={user}
                                                                                    item={item}
                                                                                    click={() =>
                                                                                        retractBid(
                                                                                            item,
                                                                                        )
                                                                                    }
                                                                                />
                                                                                {/* {props.enableRetractBid &&
                                                                            user?.id ===
                                                                                item.highest_bidder_user_id && (
                                                                                <SecondaryButton
                                                                                    label="Retract Bid"
                                                                                    btnSize="mt-2"
                                                                                    onClick={() =>
                                                                                        retractBid(
                                                                                            item,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )} */}
                                                                            </td>
                                                                        )}
                                                                    </>
                                                                </>
                                                            ) : null}

                                                            {props.page === 'bidlost' ||
                                                            props.page === 'bidwon' ? (
                                                                <>
                                                                    <td className="mb-price-td">
                                                                        <span className="hide-web">
                                                                            {t('date_closed')}
                                                                        </span>
                                                                        <span className="bwDc">
                                                                            {dateFormatFrontDay(
                                                                                item.date_closed,
                                                                            )}
                                                                        </span>
                                                                    </td>
                                                                    <td className="amount mb-price-td">
                                                                        <span className="hide-web">
                                                                            {t('winning_bid')}
                                                                        </span>
                                                                        {currencyFormat(
                                                                            parseFloat(
                                                                                item.buynowamount
                                                                                    ? item.buynowamount
                                                                                    : item.latestbid,
                                                                            ),
                                                                            props.currency,
                                                                        )}
                                                                        <CurrencyConverterFormat
                                                                            amount={
                                                                                item.buynowamount
                                                                                    ? item.buynowamount
                                                                                    : item.latestbid
                                                                            }
                                                                        />
                                                                    </td>
                                                                </>
                                                            ) : null}

                                                            {props.page === 'bidwon' ? (
                                                                <td className="mb-price-td">
                                                                    <span className="hide-web">
                                                                        Status
                                                                    </span>
                                                                    {parseInt(item.paid) == 0
                                                                        ? 'Unpaid'
                                                                        : 'Paid'}
                                                                </td>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {global.pluginConfiguration
                                                                ?.partial_payment?.enable == 1 &&
                                                            props.page === 'bidwon' ? (
                                                                <td>
                                                                    {parseFloat(item.due_amount) > 0
                                                                        ? `Due Amount of #${
                                                                              item.common_invoice
                                                                          }
                                                                is
                                                                ${currencyFormat(
                                                                    parseFloat(item.due_amount),
                                                                    props.currency,
                                                                )}`
                                                                        : '-'}
                                                                </td>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {props.page === 'bidwon' ? (
                                                                <>
                                                                    <td>
                                                                        {item.paid == 0 &&
                                                                        item.partial_amount == 1 &&
                                                                        global.pluginConfiguration
                                                                            ?.partial_payment
                                                                            ?.enable == 1 ? (
                                                                            <>
                                                                                {' '}
                                                                                <PrimaryButton
                                                                                    label={
                                                                                        'Pay Remaining'
                                                                                    }
                                                                                    className="mbv-btn"
                                                                                    onClick={() =>
                                                                                        handleRedirectInternal(
                                                                                            history,
                                                                                            `checkout/auction?id=${item.common_invoice}`,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {item.buynowpaid ||
                                                                                (parseInt(
                                                                                    item.auc_custom_field_4,
                                                                                ) == 1 &&
                                                                                    item.shipping_info ==
                                                                                        '' &&
                                                                                    parseInt(
                                                                                        item.hasshipping,
                                                                                    ) !== 1) ? (
                                                                                    <>
                                                                                        <PrimaryButton
                                                                                            label={t(
                                                                                                'view_invoice',
                                                                                            )}
                                                                                            className="mbv-btn"
                                                                                            onClick={() => {
                                                                                                if (
                                                                                                    global
                                                                                                        .storeConfigration
                                                                                                        ?.auction_dashboard_with_filter
                                                                                                        ?.value
                                                                                                ) {
                                                                                                    localStorage.setItem(
                                                                                                        'lotof',
                                                                                                        lotof,
                                                                                                    )
                                                                                                }
                                                                                                handleRedirectInternal(
                                                                                                    history,
                                                                                                    `invoice/auction?id=${item.common_invoice}`,
                                                                                                )
                                                                                            }}
                                                                                        />
                                                                                        {global
                                                                                            .storeConfigration
                                                                                            ?.refund_request_from_buyer
                                                                                            ?.value ==
                                                                                        1 ? (
                                                                                            <Requestplugin
                                                                                                lot_id={
                                                                                                    item.id
                                                                                                }
                                                                                                invoice_id={
                                                                                                    item.common_invoice
                                                                                                }
                                                                                                trigger={
                                                                                                    refundTrigger
                                                                                                }
                                                                                                setTrigger={
                                                                                                    setRefundTrigger
                                                                                                }
                                                                                                seller_refund_accept={
                                                                                                    item.seller_refund_accept
                                                                                                }
                                                                                                refund_request_notes={
                                                                                                    item.refund_request_notes
                                                                                                }
                                                                                            />
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    </>
                                                                                ) : item.shipping_info !=
                                                                                      '' &&
                                                                                  item.shipping_info !=
                                                                                      'Local Pickup' &&
                                                                                  parseInt(
                                                                                      item.shippingcost,
                                                                                  ) == 0 ? (
                                                                                    'Waiting For Seller Response'
                                                                                ) : global
                                                                                      .storeConfigration
                                                                                      ?.ship_track_info
                                                                                      ?.value ==
                                                                                  1 ? (
                                                                                    <>
                                                                                        {global
                                                                                            .storeConfigration
                                                                                            ?.buyer_checkout_seller_approve
                                                                                            ?.value ==
                                                                                            1 &&
                                                                                        item.buynow_check !=
                                                                                            '1' ? (
                                                                                            <p
                                                                                                style={{
                                                                                                    color: 'red',
                                                                                                }}
                                                                                            >
                                                                                                Invoice
                                                                                                pending
                                                                                                approval
                                                                                            </p>
                                                                                        ) : (
                                                                                            <PrimaryButton
                                                                                                label={
                                                                                                    invoiceId.includes(
                                                                                                        item.common_invoice,
                                                                                                    )
                                                                                                        ? 'Remove'
                                                                                                        : t(
                                                                                                              'add',
                                                                                                          )
                                                                                                }
                                                                                                className="mbv-btn"
                                                                                                onClick={() =>
                                                                                                    handleInvoiceChange(
                                                                                                        item.common_invoice,
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                        {/* <br></br> */}
                                                                                        {item.ss_orderid ? (
                                                                                            <PrimaryButton
                                                                                                label={
                                                                                                    'View Shipping Tracking'
                                                                                                }
                                                                                                className="mbv-btn"
                                                                                                onClick={() =>
                                                                                                    openshippingtracking(
                                                                                                        item,
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    </>
                                                                                ) : item.buynowpaid ===
                                                                                      0 &&
                                                                                  item.partial ===
                                                                                      1 ? (
                                                                                    <SecondaryButton
                                                                                        label={`Pay (${currencyFormat(
                                                                                            item.ship_offer,
                                                                                        )})`}
                                                                                        className="mbv-btn"
                                                                                        onClick={() => {
                                                                                            setAdditionalPayOpen(
                                                                                                true,
                                                                                            )
                                                                                            setAdditionalPayItem(
                                                                                                item,
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                ) : global
                                                                                      .storeConfigration
                                                                                      ?.buyer_checkout_seller_approve
                                                                                      ?.value ==
                                                                                      1 &&
                                                                                  item.buynow_check !=
                                                                                      '1' ? (
                                                                                    <p
                                                                                        style={{
                                                                                            color: 'red',
                                                                                        }}
                                                                                    >
                                                                                        Invoice
                                                                                        pending
                                                                                        approval
                                                                                    </p>
                                                                                ) : global
                                                                                      .storeConfigration
                                                                                      ?.auctionio_auto_payment_seller
                                                                                      ?.value ==
                                                                                  1 ? (
                                                                                    item.autopay >
                                                                                        0 ||
                                                                                    item.autopay_err_check >
                                                                                        0 ? (
                                                                                        <PrimaryButton
                                                                                            label={
                                                                                                invoiceId.includes(
                                                                                                    item.common_invoice,
                                                                                                )
                                                                                                    ? 'Remove'
                                                                                                    : t(
                                                                                                          'add',
                                                                                                      )
                                                                                            }
                                                                                            className="mbv-btn"
                                                                                            onClick={() =>
                                                                                                handleInvoiceChange(
                                                                                                    item.common_invoice,
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        'Waiting For Auto Pay'
                                                                                    )
                                                                                ) : (
                                                                                    <PrimaryButton
                                                                                        label={
                                                                                            invoiceId.includes(
                                                                                                item.common_invoice,
                                                                                            )
                                                                                                ? 'Remove'
                                                                                                : t(
                                                                                                      'add',
                                                                                                  )
                                                                                        }
                                                                                        className="mbv-btn"
                                                                                        onClick={() =>
                                                                                            handleInvoiceChange(
                                                                                                item.common_invoice,
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </td>

                                                                    {global?.pluginConfiguration
                                                                        ?.flutterwave_payment_gateway
                                                                        ?.enable == 1 ? (
                                                                        <td>
                                                                            {parseInt(item.paid) ==
                                                                                1 &&
                                                                            parseInt(
                                                                                item.escrow_payment,
                                                                            ) == 0 &&
                                                                            item.isrefund_request !=
                                                                                1 ? (
                                                                                <PrimaryButton
                                                                                    label={
                                                                                        'Mark As Received'
                                                                                    }
                                                                                    disabled={
                                                                                        btnloading
                                                                                    }
                                                                                    className="mbv-btn"
                                                                                    onClick={() => {
                                                                                        setBtnloading(
                                                                                            true,
                                                                                        )
                                                                                        makemarkasrecived(
                                                                                            item,
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                '-'
                                                                            )}
                                                                        </td>
                                                                    ) : null}

                                                                    {global?.pluginConfiguration
                                                                        ?.feedback?.enable == 1 &&
                                                                    global?.storeConfigration
                                                                        ?.unique_slot4?.value !=
                                                                        1 ? (
                                                                        <td>
                                                                            <SecondaryButton
                                                                                label="Feedback"
                                                                                className="mbv-btn feedBck"
                                                                                onClick={() => {
                                                                                    setFeedbackOpen(
                                                                                        true,
                                                                                    )
                                                                                    setFeedbackItem(
                                                                                        item,
                                                                                    )
                                                                                }}
                                                                            />{' '}
                                                                        </td>
                                                                    ) : null}

                                                                    {global?.pluginConfiguration
                                                                        ?.disputes?.enable == 1 ? (
                                                                        <td>
                                                                            {parseInt(item.paid) ==
                                                                                1 &&
                                                                            parseInt(
                                                                                item.escrow_payment,
                                                                            ) == 1 ? (
                                                                                <Dispute
                                                                                    from_id={
                                                                                        user?.id
                                                                                    }
                                                                                    to_id={
                                                                                        global
                                                                                            ?.storeDetails
                                                                                            ?.user_id
                                                                                    }
                                                                                    lotid={item.id}
                                                                                    auctionid={
                                                                                        item.lotof
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                '-'
                                                                            )}
                                                                        </td>
                                                                    ) : null}

                                                                    {global?.pluginConfiguration
                                                                        ?.refund?.enable == 1 &&
                                                                    global.storeConfigration
                                                                        ?.enable_request_refund
                                                                        ?.value == 1 &&
                                                                    item.paid == 1 &&
                                                                    item.escrow_payment == 0 ? (
                                                                        <>
                                                                            <td>
                                                                                {' '}
                                                                                {item.payment_type !=
                                                                                'Wallet' ? (
                                                                                    <RequestRefund
                                                                                        lot_id={
                                                                                            item.id
                                                                                        }
                                                                                        invoice_id={
                                                                                            item.common_invoice
                                                                                        }
                                                                                        trigger={
                                                                                            refundTrigger
                                                                                        }
                                                                                        setTrigger={
                                                                                            setRefundTrigger
                                                                                        }
                                                                                        seller_refund_accept={
                                                                                            item.seller_refund_accept
                                                                                        }
                                                                                        isrefund_request={
                                                                                            item.isrefund_request
                                                                                        }
                                                                                        refund_request_notes={
                                                                                            item.refund_request_notes
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    'Refund Is Not Applicable For Wallet Payment'
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                <RequestRefund
                                                                                    lot_id={item.id}
                                                                                    invoice_id={
                                                                                        item.common_invoice
                                                                                    }
                                                                                    trigger={
                                                                                        refundTrigger
                                                                                    }
                                                                                    setTrigger={
                                                                                        setRefundTrigger
                                                                                    }
                                                                                    seller_refund_accept={
                                                                                        item.seller_refund_accept
                                                                                    }
                                                                                    isrefund_request={
                                                                                        item.isrefund_request
                                                                                    }
                                                                                    status="status"
                                                                                />
                                                                            </td>
                                                                        </>
                                                                    ) : global.storeConfigration
                                                                          ?.enable_request_refund
                                                                          ?.value == 1 ? (
                                                                        <>
                                                                            <td>-</td>
                                                                            <td>
                                                                                {item.paid ? (
                                                                                    <p>
                                                                                        Fund
                                                                                        Released To
                                                                                        Seller.
                                                                                    </p>
                                                                                ) : (
                                                                                    '-'
                                                                                )}
                                                                            </td>
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {global?.pluginConfiguration
                                                                        ?.refund?.enable == 1 &&
                                                                    global.storeConfigration
                                                                        ?.enable_request_refund
                                                                        ?.value == 1 &&
                                                                    item.item_returned == 1 &&
                                                                    global?.pluginConfiguration
                                                                        ?.flutterwave_payment_gateway
                                                                        ?.enable != 1 ? (
                                                                        <td>
                                                                            <p>
                                                                                Amount (
                                                                                {currencyFormat(
                                                                                    item.refund,
                                                                                )}
                                                                                ) Refeunded on{' '}
                                                                                {dateFormatFrontDay(
                                                                                    item.refund_request_date,
                                                                                )}
                                                                            </p>
                                                                        </td>
                                                                    ) : null}
                                                                </>
                                                            ) : null}

                                                            {props.page === 'buynow' ||
                                                            props.page === 'make_an_offer' ? (
                                                                <>
                                                                    {props.page ==
                                                                    'make_an_offer' ? (
                                                                        <td className="onlyPick mb-price-td">
                                                                            <span className="hide-web">
                                                                                Date Purchased
                                                                            </span>
                                                                            {dateFormatFrontDay(
                                                                                item.date_requested,
                                                                            )}{' '}
                                                                        </td>
                                                                    ) : (
                                                                        <td className="onlyPick mb-price-td">
                                                                            <span className="hide-web">
                                                                                Date Purchased
                                                                            </span>
                                                                            {dateFormatFrontDay(
                                                                                item.buynowdate_added,
                                                                            )}{' '}
                                                                        </td>
                                                                    )}
                                                                    <td className="onlyPick mb-price-td">
                                                                        <span className="hide-web">
                                                                            Price
                                                                        </span>
                                                                        {props.page ===
                                                                        'make_an_offer'
                                                                            ? currencyFormat(
                                                                                  parseFloat(
                                                                                      item.proposed_amount,
                                                                                  ),
                                                                                  props.currency,
                                                                              )
                                                                            : currencyFormat(
                                                                                  parseFloat(
                                                                                      global
                                                                                          ?.storeConfigration
                                                                                          ?.buy_now_with_qty
                                                                                          ?.value ==
                                                                                          1
                                                                                          ? item.buynowamount
                                                                                          : item.bprice,
                                                                                  ),
                                                                                  props.currency,
                                                                              )}
                                                                        <CurrencyConverterFormat
                                                                            amount={item.bprice}
                                                                        />
                                                                    </td>
                                                                    {parseInt(item.paid) == 0 &&
                                                                    item.common_invoice &&
                                                                    item.status == 'Accepted' ? (
                                                                        <td>
                                                                            {global
                                                                                .storeConfigration
                                                                                ?.buyer_checkout_seller_approve
                                                                                ?.value == 1 &&
                                                                            item.buynow_check !=
                                                                                '1' ? (
                                                                                <p
                                                                                    style={{
                                                                                        color: 'red',
                                                                                    }}
                                                                                >
                                                                                    Invoice pending
                                                                                    approval
                                                                                </p>
                                                                            ) : (
                                                                                <PrimaryButton
                                                                                    label={
                                                                                        invoiceId.includes(
                                                                                            item.common_invoice,
                                                                                        )
                                                                                            ? 'Remove'
                                                                                            : t(
                                                                                                  'add',
                                                                                              )
                                                                                    }
                                                                                    className="mbv-btn"
                                                                                    onClick={() =>
                                                                                        handleInvoiceChangeupdate(
                                                                                            item.common_invoice,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </td>
                                                                    ) : item.status == 'Pending' ||
                                                                      item.status == 'Rejected' ? (
                                                                        <td className="text-center">
                                                                            {item.status ==
                                                                                'Pending' &&
                                                                            item.market_status ==
                                                                                'sold'
                                                                                ? 'Rejected'
                                                                                : item.status}
                                                                        </td>
                                                                    ) : (
                                                                        <td className="multipleInvoiceButtons">
                                                                            {item.buynowpaid ===
                                                                                0 &&
                                                                            item.partial === 1 ? (
                                                                                <SecondaryButton
                                                                                    label={`Pay (${currencyFormat(
                                                                                        item.ship_offer,
                                                                                    )})`}
                                                                                    className="mbv-btn"
                                                                                    onClick={() => {
                                                                                        setAdditionalPayOpen(
                                                                                            true,
                                                                                        )
                                                                                        setAdditionalPayItem(
                                                                                            item,
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    {global
                                                                                        ?.storeConfigration
                                                                                        ?.unique_slot4
                                                                                        ?.value !=
                                                                                    1 ? (
                                                                                        <PrimaryButton
                                                                                            label="View Invoice"
                                                                                            className="mbv-btn"
                                                                                            onClick={() => {
                                                                                                handleRedirectInternal(
                                                                                                    history,
                                                                                                    `invoice/buynow?id=${item.common_invoice}`,
                                                                                                )
                                                                                            }}
                                                                                        />
                                                                                    ) : null}
                                                                                    {global
                                                                                        ?.storeConfigration
                                                                                        ?.unique_slot4
                                                                                        ?.value ==
                                                                                    1 ? (
                                                                                        <>
                                                                                            {item.paid_approved ==
                                                                                            0 ? (
                                                                                                <p className="awaitingApproval">
                                                                                                    Awaiting
                                                                                                    Approval
                                                                                                </p>
                                                                                            ) : item.paid_approved ==
                                                                                              1 ? (
                                                                                                <>
                                                                                                    <p className="orderApproved">
                                                                                                        Order
                                                                                                        Approved
                                                                                                    </p>
                                                                                                    <PrimaryButton
                                                                                                        label="View Invoice"
                                                                                                        className="mbv-btn"
                                                                                                        onClick={() => {
                                                                                                            handleRedirectInternal(
                                                                                                                history,
                                                                                                                `invoice/buynow?id=${item.common_invoice}`,
                                                                                                            )
                                                                                                        }}
                                                                                                    />
                                                                                                    {global
                                                                                                        ?.storeConfigration
                                                                                                        ?.checkout_terms_and_condtions
                                                                                                        ?.value ==
                                                                                                    1 ? (
                                                                                                        <SecondaryButton
                                                                                                            btnSize="minMaxContent"
                                                                                                            onClick={() => {
                                                                                                                setPopupchecked(
                                                                                                                    true,
                                                                                                                )
                                                                                                            }}
                                                                                                        >
                                                                                                            Terms
                                                                                                            &
                                                                                                            Conditions
                                                                                                        </SecondaryButton>
                                                                                                    ) : null}
                                                                                                    {global
                                                                                                        ?.storeConfigration
                                                                                                        ?.unique_slot4
                                                                                                        ?.value ==
                                                                                                    1 ? (
                                                                                                        <SecondaryButton
                                                                                                            btnSize="minMaxContent"
                                                                                                            onClick={() => {
                                                                                                                setFeedbackOpen(
                                                                                                                    true,
                                                                                                                )
                                                                                                                setFeedbackItem(
                                                                                                                    item,
                                                                                                                )
                                                                                                            }}
                                                                                                        >
                                                                                                            Feedback
                                                                                                        </SecondaryButton>
                                                                                                    ) : (
                                                                                                        ''
                                                                                                    )}
                                                                                                </>
                                                                                            ) : item.paid_approved ==
                                                                                              2 ? (
                                                                                                <p className="orderDenied">
                                                                                                    Order
                                                                                                    Denied
                                                                                                </p>
                                                                                            ) : null}
                                                                                        </>
                                                                                    ) : null}
                                                                                    {global
                                                                                        .storeConfigration
                                                                                        ?.refund_request_from_buyer
                                                                                        ?.value ==
                                                                                    1 ? (
                                                                                        <Requestplugin
                                                                                            lot_id={
                                                                                                item.id
                                                                                            }
                                                                                            invoice_id={
                                                                                                item.common_invoice
                                                                                            }
                                                                                            trigger={
                                                                                                refundTrigger
                                                                                            }
                                                                                            setTrigger={
                                                                                                setRefundTrigger
                                                                                            }
                                                                                            seller_refund_accept={
                                                                                                item.seller_refund_accept
                                                                                            }
                                                                                            refund_request_notes={
                                                                                                item.refund_request_notes
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </td>
                                                                    )}

                                                                    {global?.pluginConfiguration
                                                                        ?.flutterwave_payment_gateway
                                                                        ?.enable == 1 ? (
                                                                        <td>
                                                                            {parseInt(
                                                                                item.buynowpaid,
                                                                            ) == 1 &&
                                                                            parseInt(
                                                                                item.escrow_payment,
                                                                            ) == 0 &&
                                                                            item.isrefund_request !=
                                                                                1 ? (
                                                                                <PrimaryButton
                                                                                    label={
                                                                                        'Mark As Received'
                                                                                    }
                                                                                    disabled={
                                                                                        btnloading
                                                                                    }
                                                                                    className="mbv-btn"
                                                                                    onClick={() => {
                                                                                        setBtnloading(
                                                                                            true,
                                                                                        )
                                                                                        makemarkasrecived(
                                                                                            item,
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                '-'
                                                                            )}
                                                                        </td>
                                                                    ) : null}

                                                                    {global?.pluginConfiguration
                                                                        ?.feedback?.enable == 1 &&
                                                                    global?.storeConfigration
                                                                        ?.unique_slot4?.value !=
                                                                        1 ? (
                                                                        <td>
                                                                            <SecondaryButton
                                                                                label="Feedback"
                                                                                className="mbv-btn feedBck"
                                                                                onClick={() => {
                                                                                    setFeedbackOpen(
                                                                                        true,
                                                                                    )
                                                                                    setFeedbackItem(
                                                                                        item,
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    ) : null}

                                                                    {global?.pluginConfiguration
                                                                        ?.disputes?.enable == 1 ? (
                                                                        <td>
                                                                            <Dispute
                                                                                from_id={user?.id}
                                                                                to_id={
                                                                                    global
                                                                                        ?.storeDetails
                                                                                        ?.user_id
                                                                                }
                                                                                lotid={item.id}
                                                                                auctionid={
                                                                                    item.lotof
                                                                                }
                                                                            />
                                                                        </td>
                                                                    ) : null}
                                                                    {global?.pluginConfiguration
                                                                        ?.refund?.enable == 1 &&
                                                                    global.storeConfigration
                                                                        ?.enable_request_refund
                                                                        ?.value == 1 &&
                                                                    item.item_returned == 0 ? (
                                                                        <>
                                                                            <td>
                                                                                {item.payment_type !=
                                                                                'Wallet' ? (
                                                                                    <RequestRefund
                                                                                        lot_id={
                                                                                            item.id
                                                                                        }
                                                                                        invoice_id={
                                                                                            item.common_invoice
                                                                                        }
                                                                                        trigger={
                                                                                            refundTrigger
                                                                                        }
                                                                                        setTrigger={
                                                                                            setRefundTrigger
                                                                                        }
                                                                                        seller_refund_accept={
                                                                                            item.seller_refund_accept
                                                                                        }
                                                                                        isrefund_request={
                                                                                            item.isrefund_request
                                                                                        }
                                                                                        refund_request_notes={
                                                                                            item.refund_request_notes
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    'Refund Is Not Applicable For Wallet Payment'
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                <RequestRefund
                                                                                    lot_id={item.id}
                                                                                    invoice_id={
                                                                                        item.common_invoice
                                                                                    }
                                                                                    trigger={
                                                                                        refundTrigger
                                                                                    }
                                                                                    setTrigger={
                                                                                        setRefundTrigger
                                                                                    }
                                                                                    seller_refund_accept={
                                                                                        item.seller_refund_accept
                                                                                    }
                                                                                    isrefund_request={
                                                                                        item.isrefund_request
                                                                                    }
                                                                                    status="status"
                                                                                />
                                                                            </td>
                                                                        </>
                                                                    ) : global.storeConfigration
                                                                          ?.enable_request_refund
                                                                          ?.value == 1 ? (
                                                                        <>
                                                                            <td>-</td>
                                                                            <td>
                                                                                {item.paid ? (
                                                                                    <p>
                                                                                        Fund
                                                                                        Released To
                                                                                        Seller.
                                                                                    </p>
                                                                                ) : (
                                                                                    '-'
                                                                                )}
                                                                            </td>
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {global?.pluginConfiguration
                                                                        ?.refund?.enable == 1 &&
                                                                    global.storeConfigration
                                                                        ?.enable_request_refund
                                                                        ?.value == 1 &&
                                                                    item.item_returned == 1 &&
                                                                    global?.pluginConfiguration
                                                                        ?.flutterwave_payment_gateway
                                                                        ?.enable != 1 ? (
                                                                        <td>
                                                                            <p>
                                                                                Amount (
                                                                                {currencyFormat(
                                                                                    item.refund,
                                                                                )}
                                                                                ) Refeunded on{' '}
                                                                                {dateFormatFrontDay(
                                                                                    item.refund_request_date,
                                                                                )}
                                                                            </p>
                                                                        </td>
                                                                    ) : null}
                                                                </>
                                                            ) : null}
                                                        </tr>
                                                    ) : null}
                                                </>
                                            )
                                        })
                                    )}
                                    {((props.page === 'bidwon' &&
                                        props.checkout_but_pos != 'top') ||
                                        (props.page === 'make_an_offer' &&
                                            props.checkout_but_pos != 'top')) &&
                                    props.sch_type != 1 &&
                                    props.sch_type != 2 ? (
                                        <tr>
                                            {props.page === 'make_an_offer' ? (
                                                <td colSpan="4"></td>
                                            ) : (
                                                <td colSpan="6"></td>
                                            )}
                                            {props.abandoned ? null : (
                                                <td colSpan="2" className="mbutton-xs">
                                                    {props.storeTheme != 'Lotsofauctions' && (
                                                        <PrimaryButton
                                                            label={`Checkout (${checkoutLength})`}
                                                            className="mbv-btn"
                                                            onClick={() => redirect_checkout()}
                                                            disabled={!checkoutLength}
                                                        />
                                                    )}
                                                </td>
                                            )}
                                        </tr>
                                    ) : null}
                                </tbody>
                            </table>
                        )}
                        {props.gridView && Items.length !== 0 && (
                            <div
                                className="table-responsive lineItemBidTable mt-2 sublots-table mySubmitTable"
                                id="sublot"
                            >
                                <LotListProduct
                                    listview={false}
                                    searchResult={Items}
                                    className="liquidationBuyCard"
                                    cardType={'grid'}
                                    hideQuickBid={global.storeDetails?.theme == 19 ? true : false}
                                />
                            </div>
                        )}
                    </div>
                    {props.abandoned ? (
                        <Pagination
                            count={Math.ceil(abandonedOrders.totaProducts / 10)}
                            page={abandonedOrders.page}
                            onChange={onHandlePage}
                        />
                    ) : (
                        <Pagination
                            count={Math.ceil(totalItems / search.sh_limit)}
                            page={search.page}
                            onChange={onHandlePage}
                        />
                    )}
                </>
            ) : (
                <NoRecordsFound />
            )}

            {props.sliderTheme == 'multiseller' ? (
                <MultisellerSlider
                    storeTheme={props.storeTheme}
                    selectedLot={selectedProduct}
                    handleClose={handleClose}
                    currency={props.currency}
                    sliderTheme={props.sliderTheme}
                    itemDetails={Items}
                    from={'card'}
                    getAllProducts={(e) => {
                        return true
                    }}
                />
            ) : (
                <SlidePanel
                    type="right"
                    selectedLot={selectedProduct}
                    temDetails={Items}
                    handleClose={handleClose}
                    from={'card'}
                    getAllProducts={() => {
                        return true
                    }}
                />
            )}

            <Popup
                open={popup_open}
                handleClose={(e) => setPopupopup(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Shipping Info"
            >
                {Object.keys(display_address).length > 0 ? (
                    <>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Shipping User Info: '}</b>
                                {display_address.first_name + ' ' + display_address.last_name}
                            </div>
                            <div className="col-6">
                                <b>{'Shipping Address: '}</b>
                                {display_address.address}
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Email: '}</b>
                                {display_address.email}
                            </div>
                            <div className="col-6">
                                <b>{'Tracking Number: '}</b>
                                {display_address.tracking_number
                                    ? display_address.tracking_number
                                    : '-'}
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Phone: '}</b>
                                {display_address.phone}
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Tracking Service: '}</b>
                                {global.pluginConfiguration?.shipping?.service_key
                                    ? global.pluginConfiguration.shipping.service_key
                                    : '-'}
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Tracking Info: '}</b>
                                {display_address.localpickup ? 'Local-Pick-Up' : 'Shipping'}
                            </div>
                        </div>
                        <br></br>
                        <div className="actionWrapper">
                            <div className="row">
                                <div className="col-3">
                                    {' '}
                                    <PrimaryButton
                                        label={`Close`}
                                        className="mbv-btn"
                                        onClick={(e) => setPopupopup(false)}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>Loading...</>
                )}
            </Popup>
            <Popup
                open={feedbackOpen}
                handleClose={(e) => setFeedbackOpen(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Feedback"
            >
                <AddFeedback
                    item={feedbackItem}
                    closePopup={() => setFeedbackOpen(false)}
                    clearSelectedItem={() => setFeedbackItem({})}
                />
            </Popup>
            <Popup
                open={additionalPayOpen}
                handleClose={(e) => setAdditionalPayOpen(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Additional Pay"
            >
                <AdditionalPayment
                    item={additionalPayItem}
                    closePopup={() => setAdditionalPayOpen(false)}
                    clearSelectedItem={() => setAdditionalPayItem({})}
                    trigger={refundTrigger}
                    setTrigger={setRefundTrigger}
                />
            </Popup>
            <CustomDialog
                open={popupchecked}
                function={() => setPopupchecked(false)}
                size="md"
                className="save-search-modal"
                title="Terms and Conditions"
                closeBtn={true}
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: `${global?.storeDetails?.terms_condition}${termscondtions}<p><b>Terms and conditions accepted by the ${user?.first_name} ${user?.last_name}.</b</p>`,
                    }}
                ></div>
            </CustomDialog>
            <Popup
                open={messageChecked}
                handleClose={() => {
                    setMessageChecked(false)
                    setBuynowId({})
                }}
                size="md"
                className="save-search-modal"
                modaltitle="Upload Files"
            >
                <InvoiceMessage
                    project_id={buynowId?.common_invoice}
                    chatUser={{
                        first_name: `${buynowId?.title} -`,
                        last_name: ` ${buynowId?.common_invoice}`,
                        id: buynowId?.user_id,
                    }}
                    hide_header={1}
                    hide_message_box={1}
                />
            </Popup>
        </>
    )
}

export default AllBids
